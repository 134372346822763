// PrivacyPolicy.js
import React from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import Header from './Header';
import Footer from './Footer';

const PrivacyPolicy = () => {
  return (
    <MainDiv>
        <GlobalStyle />
        <Header />
        <PrivacyPolicyContainer>
            <Title>Privacy Policy</Title>

            <Text>
                This Privacy Policy describes how your information is collected, used, and shared
                when you use our Chrome extension, Kait-AI or "Kait". By using Kait, you
                agree to the collection and use of information in accordance with this policy.
            </Text>

            <SectionTitle>Information We Collect</SectionTitle>
            <Text>
                The Extension may collect limited information necessary to provide its functionality.
                This may include: email address, usage data, and error logs.
            </Text>

            <WeCollectUL>
                <WeCollectLI>Non-personal usage data such as error logs and performance metrics.</WeCollectLI>
                <WeCollectLI>Personal information only if explicitly provided by you (e.g., email address).</WeCollectLI>
            </WeCollectUL>

            <SectionTitle>How We Use Your Information</SectionTitle>
            <Text>
                The information we collect is used solely for the purpose of improving and maintaining
                the Extension, providing support, and enhancing user experience.
            </Text>

            <SectionTitle>Third-Party Services</SectionTitle>
            <Text>
                We do not share your information with third parties except when required by law or
                with your explicit consent.
            </Text>

            <SectionTitle>Data Security</SectionTitle>
            <Text>
                We take reasonable measures to protect your data from unauthorized access, loss, or
                misuse. However, no method of transmission or storage is 100% secure.
            </Text>

            <SectionTitle>Changes to This Privacy Policy</SectionTitle>
            <Text>
                We may update this Privacy Policy from time to time. Changes will be posted here
                with an updated "Last Updated" date.
            </Text>

            <SectionTitle>Contact Us</SectionTitle>
            <Text>
                If you have any questions about this Privacy Policy, you can contact us at
                aikaithelp@gmail.com.
            </Text>

            <LastUpdatedDiv>
                <LastUpdatedText>Last Updated: January 2025</LastUpdatedText>
            </LastUpdatedDiv>

        </PrivacyPolicyContainer>
   
        <Footer />

    </MainDiv>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Nerko One', sans-serif;
    background-color: #2F284F;
  }
`;

const MainDiv = styled.div`
    padding: 0;
    margin: 0;
`

const PrivacyPolicyContainer = styled.div`
    padding: 20px;
    color: #333;
    line-height: 1.6;
    max-width: 800px;
    margin: 5% auto;
    border-radius: 8px;
    border: 1px solid #4EB5A9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #6157E1;
`;

const Title = styled.h1`
    font-size: 2rem;
    margin-bottom: 20px;
    color: white;
`;

const SectionTitle = styled.h2`
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 10px;
    color: white;
`;

const Text = styled.p`
    font-size: 1rem;
    margin-bottom: 15px;
    color: white;
`;

const WeCollectUL = styled.ul`
    list-style-type: disc;
    margin-left: 20px;
    color: white;
`;
const WeCollectLI = styled.li`
    list-style-type: disc;
    margin-left: 20px;
`;

const LastUpdatedDiv = styled.div`
    text-align: center;
`

const LastUpdatedText = styled.p`
    color: white;
`

export default PrivacyPolicy;
