import Header from "./Components/Header";
import Main from "./Components/Main";

import styled, { createGlobalStyle } from "styled-components";

function App() {
  return (
    <AppDiv>
    <GlobalStyle />
      <HeaderDiv>
        <Header />
      </HeaderDiv>
      <AppInnerDiv>
        <MainDiv>
          <Main />
        </MainDiv>
        
        <FooterDiv>

        </FooterDiv>
      </AppInnerDiv>
    </AppDiv>
  );
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Nerko One', sans-serif;
    background-color: #2F284F;
  }
`;

const AppDiv = styled.div`

`

const AppInnerDiv = styled.div`
  height: 100vh;
  width: 50vw;
  margin: auto;
`

const HeaderDiv = styled.div`
 
`

const MainDiv = styled.div`

`

const FooterDiv = styled.div`
  
`

export default App;
